
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.retail-rocket {
  @include media(md-max) {
    overflow: hidden;
  }

  @include media(lg) {
    max-height: 0;
    padding-bottom: 0;
    visibility: hidden;
  }

  &--init {
    @include media(lg) {
      overflow: hidden;
    }
  }
}

.block--default + .retail-rocket.block--default,
[data-theme='dark'] .block--special + .block--default {
  @include media(lg) {
    padding-top: 0;
  }
}

.retail-rocket__wrapper {
  display: flex;
  flex-direction: column;

  @include media(lg) {
    margin: 0 -13px;
    padding: 0 13px;
  }
}

.retail-rocket__controls {
  display: none;

  @include media(lg) {
    align-self: center;
    margin-top: 40px;
  }
}

.retail-rocket--init .retail-rocket__controls {
  @include media(lg) {
    display: flex;
  }
}

.retail-rocket__title {
  margin-bottom: 24px;

  @include media(lg) {
    margin-bottom: 32px;
  }
}

.retail-rocket__list {
  @include ulres;

  display: flex;

  &--no-slider {
    @include z-index(base);

    --margin: 20px;

    margin-right: calc(var(--margin) * -1);
    margin-left: calc(var(--margin) * -1);
    padding-left: var(--margin);
    overflow-x: auto;
    scrollbar-width: none;
    scroll-snap-type: x proximity;

    @include media(md) {
      --margin: calc((100vw - #{$ui-md-c}) / 2);
    }

    @include media(lg) {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: repeat(2, 1fr);
      margin-right: 0;
      margin-left: 0;
      padding-left: 0;
      overflow: visible;
    }

    @include media(xl) {
      grid-template-columns: repeat(3, 1fr);
    }

    // фикс для Сафари
    &::after {
      padding-right: var(--margin);

      @include media(md-max) {
        content: '';
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.retail-rocket__item {
  flex-shrink: 0;
  max-width: 100%;
}

.retail-rocket__list--no-slider .retail-rocket__item {
  width: calc(100vw - 40px);

  @include media(sm-max) {
    min-width: 280px;
  }

  @include media(md) {
    width: 336px;
  }

  @include media(lg) {
    width: unset;
  }

  &:not(:last-child) {
    margin-right: 8px;

    @include media(md) {
      margin-right: 24px;
    }

    @include media(lg) {
      margin-right: 0;
    }
  }
}

.retail-rocket__duration {
  display: block;
  margin-top: 16px;

  @include media(lg) {
    margin-top: 20px;
  }
}

.retail-rocket__card {
  width: 100%;
  height: 100%;
}
